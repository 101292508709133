import ButtonLink from '../../common/button/ButtonLink';
import Image from 'next/image';

export default function DataManagement({dataIt}) {

	const belowTitle=dataIt.belowTitle.split(' ');
    return (
		<section className="section border-0 section-primary section-angled section-angled-reverse 
		section-funnel position-relative overflow-visible lazyloaded" style={{marginTop:"4em"}}>
					<div className="section-angled-layer-top section-angled-layer-increase-angle 
					bg-primary" 
                    style={{paddingTop:"3em"}}>

					</div>
					
					
					<div className="container pb-5 mb-5" style={{marginTop:"-3em"}}>
						<div className="row no-gutters justify-content-center pb-md-3 mb-md-3">
						<div className="d-none d-md-block col-md-6 col-lg-3 
						col-xl-3 offset-lg-3 offset-xl-1">
							<div style={{width:"14em", height:"14em",position:"relative"}}>
								<Image
									layout="fill"
									objectFit="cover"
									objectPosition="center"
									loading="lazy"
									src="/images/it_security/protection.png" 
									data-src="/images/it_security/protection.png" 
									alt={dataIt.upperAlt} 
								/>
							</div>
						</div>
						<div className="col-md-6 d-flex flex-column">
								<h2 className="font-weight-bold text-8 line-height-2 
								mb-3 text_align_center">
									{dataIt.upTitle}</h2>
								<p className="mb-4 pb-lg-2 line-height-2 
								text-5 text_align_center">
									{dataIt.upParagraf}
								</p>
								<div className="text_align_center">
									<ButtonLink 
										title={dataIt.buttonName}
										page={dataIt.upLink}/>
								</div>
						</div>
					</div>
					<div className="row align-items-center pb-md-5 mb-md-5">
							<div className="col-12 col-md-6 col-lg-6 mt-1 d-flex flex-column">
								<h2 className="font-weight-bold text-8 pt-3 pt-md-4 mt-3 mb-lg-3 mb-2 text_align_center" 
								>{belowTitle[0]} {belowTitle[1]}
								{belowTitle[3]}
								</h2>
								<p className="text-color-light text_align_center
								 mb-4 pb-lg-2 line-height-2 text-5" >  
								{dataIt.belowPargraf}</p>
								<div className="text_align_center">
								<ButtonLink title={dataIt.buttonName} page={dataIt.belowLink}/>
								
								</div>
							</div>
							<div className="col-md-6 col-lg-4 d-none d-md-block">
								<div className="img-fluid z-index-1 position-relative"
								style={{marginBottom: "0%", width:"16em", height:"16em"}}>
									<Image
										layout="fill"
										objectFit="cover"
										objectPosition="center"
										loading="lazy"
										src="/images/it_security/reverse.png"  
										alt= {dataIt.belowAlt} 
										/>
								</div>
                              
							</div>
						</div>
					</div>
					<div className="section-funnel-layer-bottom" style={{paddingBottom:"8em"}}>
						<div className="section-funnel-layer"></div>
						<div className="section-funnel-layer"></div>
					</div>
				</section>
    )
}
